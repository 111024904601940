<template>

<app-page>

	<app-page-head :title="title" back="Accesses">

		<app-page-head-action v-if="is.new" :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action v-if="is.new || is.existing" :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action route="Access.Delete" v-if="!isNew && model.user.level === 2" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" is-divided="true" :grow="true">

			<template v-if="isNew">

				<app-input-text :loading="is.checking" v-model="model.user.email" :validation="$v.model.user.email" label="Email" placeholder="Enter email address" notes="Please enter the e-mail address of the user you wish to grant access to and the system will check if they already have an account." />
			
				<template v-if="is.new">

					<app-input-text v-model="model.user.name" :validation="$v.model.user.name" label="Name" placeholder="Enter name" :maxlength="32" />
					<app-input-text v-model="extra.password" :password="true" :validation="$v.extra.password" label="Password" placeholder="Enter password" notes="Your password should be at least 8 characters long, have both upper and lower case, and at least one number." />
					<app-input-text v-model="extra.password2" :password="true" :validation="$v.extra.password2" label="Confirm Password" placeholder="Repeat password" />

				</template>

				<template v-if="is.existing">

					<app-input-text :disabled="true" v-model="model.user.name" :validation="$v.model.user.name" label="Name" placeholder="Enter name" :maxlength="32" />

				</template>

				<app-notice v-if="is.added">This user already has access granted to this guide.</app-notice>

			</template>

			<template v-if="!isNew">

				<app-input-text :disabled="true" v-model="model.user.email" :validation="$v.model.user.email" label="Email" placeholder="Enter email address" />
				<app-input-text :disabled="true" v-model="model.user.name" :validation="$v.model.user.name" label="Name" placeholder="Enter name" :maxlength="32" />

			</template>

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			is: {
				checking: false,
				checked: false,
				added: false,
				new: false,
				existing: false
			},
			model: {
				id: 0,
				user: {
					name: '',
					email: '',
					level: 0
				}
			},
			extra: {
				password: '',
				password2: ''
			},
			param: 'access',
			noun: 'Access',
			store: 'access'
		}

	},

	validations: {
		model: {
			user: {
				name: {
					required,
					maxLength: maxLength(32)
				},
				email: {
					required,
					email
				}
			}
		},
		extra: {
			password: {
				/*required,
				minLength: minLength(8),
				containsUppercase: function(value) {
					return /[A-Z]/.test(value)
				},
				containsLowercase: function(value) {
					return /[a-z]/.test(value)
				},
				containsNumber: function(value) {
					return /[0-9]/.test(value)
				}*/
			},
			password2: {
				//required,
				sameAsPassword: sameAs('password')
			}
		}
	},

	computed: {

		email: function() {

			return this.model.user.email

		}

	},

	watch: {

		email: function() {
		
			if (this.$v.model.user.email.$invalid) {

				this.is.checking = false
				this.is.checked = false
				this.is.new = false 
				this.is.existing = false
				this.is.added = false

			} else {

				this.$api.cancel()

				this.is.checking = true

				this.$api.get('access/check', {
					email: this.model.user.email
				}).then(function(json) {

					this.is.checking = false
					this.is.checked = true
					this.is.new = false 
					this.is.existing = false
					this.is.added = false

					if (json.user) {

						this.is.existing = true

						if (json.hasAccess) {

							this.is.added = true
								
						}

						this.model.user.name = json.user.name

					} else {

						this.is.new = true
						this.model.user.name = ''
					
					}

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>
